























import { computed, defineComponent, PropType } from '@nuxtjs/composition-api'
import { faHeart as farHeart } from '@fortawesome/free-regular-svg-icons/faHeart'
import { faHeart } from '@fortawesome/free-solid-svg-icons'
import { Search } from '~/models/search/types'

export default defineComponent({
  props: {
    search: {
      type: Object as PropType<Search>,
      required: true
    }
  },
  emits: ['add-favorite', 'remove-favorite'],
  setup(props, { emit }) {
    const icons = computed(() => ({ heart: faHeart, rHeart: farHeart }))
    const handleFavoriteChange = (isFavorite?: boolean) => {
      const { id } = props.search
      if (isFavorite) {
        emit('remove-favorite', id)
      } else {
        emit('add-favorite', id)
      }
    }
    return { icons, handleFavoriteChange }
  }
})
